import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import _ from 'lodash';

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Detail from './pages/Detail'

function App() {
	const [nav, setNav] = useState(null);
	const [navDate, setNavDate] = useState(null);
	const [grouped, setGrouped] = useState(null);
	const [sharia, setSharia] = useState(null);

	const getNav = async () => {
		await fetch('https://api.moneywork.xyz/nav').then(response => {
			return response.json()
		}).then(data => {

			const _data = data.dataNav.sort(function (a, b) {
				return b.navOneYear - a.navOneYear;
			});

			// console.log(_data)

			const grouped = _.groupBy(_data, (item) => {
				return item.fund_type;
			});
			// console.log(grouped)
			const sharia = _data.filter(item => {
				return item.is_sharia === 1;
			});
			// console.log(sharia)

			setNavDate(data.nav_date)

			setNav(_data)
			setGrouped(grouped)
			setSharia(sharia)
		}).catch(err => {
			console.log(err.message)
		})
	}

	useEffect(() => {
		getNav()
	}, ['nav']);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home nav={nav} navDate={navDate} />} />
				<Route path="/product/:fund_code" element={<Detail />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
