import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import formatDate from '../utils/formatDate'
import formatNumber from '../utils/formatNumber'
import nFormatter from '../utils/nFormatter'

function MutualFund({ nav, navDate }) {
  const [product, setProduct] = useState(null)

  return (
    <>
      <section className="explore-section section-padding" id="section_2">
        <div className="container">
          <div className="row">

            <div className="col-12 text-center">
              <h2 className="mb-0">Discover Mutual Fund</h2>
              <h5 className="mb-4">{navDate && formatDate(navDate, 'DD MMMM YYYY')}</h5>
            </div>

          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="design-tab" data-bs-toggle="tab"
                  data-bs-target="#design-tab-pane" type="button" role="tab"
                  aria-controls="design-tab-pane" aria-selected="true">All</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="design-tab" data-bs-toggle="tab"
                  data-bs-target="#design-tab-pane" type="button" role="tab"
                  aria-controls="design-tab-pane" aria-selected="true">Pendapatan Tetap</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="marketing-tab" data-bs-toggle="tab"
                  data-bs-target="#marketing-tab-pane" type="button" role="tab"
                  aria-controls="marketing-tab-pane" aria-selected="false">Pasar Uang</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="finance-tab" data-bs-toggle="tab"
                  data-bs-target="#finance-tab-pane" type="button" role="tab"
                  aria-controls="finance-tab-pane" aria-selected="false">Saham</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="music-tab" data-bs-toggle="tab"
                  data-bs-target="#music-tab-pane" type="button" role="tab"
                  aria-controls="music-tab-pane" aria-selected="false">Syariah</button>
              </li>

              <li className="nav-item" role="presentation">
                <button className="nav-link" id="education-tab" data-bs-toggle="tab"
                  data-bs-target="#education-tab-pane" type="button" role="tab"
                  aria-controls="education-tab-pane" aria-selected="false">Campuran</button>
              </li>
            </ul>
          </div>
        </div>

        <div className="container">
          <div className="row">

            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="design-tab-pane" role="tabpanel"
                  aria-labelledby="design-tab" tabIndex="0">
                  <div className="row">
                    {
                      nav && nav.map((row, id) => (
                        <div
                          className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-4"
                          key={id}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setProduct(row)}
                        >
                          <div className="custom-block bg-white shadow-lg">
                            {/* <Link to={`/product/${row.fund_code}`}> */}
                            <div className="d-flex">
                              <div>
                                <h5 className="mb-2">{row.fund_name}</h5>
                                <p className="mb-2">{row.fund_type}</p>
                                <p className="mb-0 text-success">
                                  <strong>{formatNumber(row.navOneYear, 3)}%</strong> AR &#183; {row.fund_ccy} <strong>{nFormatter(row.navAum, 2)}</strong> AUM
                                </p>
                              </div>

                              <span className={id + 1 <= 15 ? 'badge bg-success rounded-pill ms-auto' : 'badge bg-design rounded-pill ms-auto'}>{id + 1}</span>
                            </div>

                            {/* <img src="assets/images/topics/undraw_Remote_design_team_re_urdx.png"
																	className="custom-block-image img-fluid" alt="" /> */}
                            {/* </Link> */}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div class="modal fade modal-lg" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{product && product.fund_type}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {
                product && <><h5>{product.fund_name}</h5>
                  <p>{product.im_name}</p>
                  <p>Since {formatDate(product.launch_date, 'DD MMMM YYYY')}</p>
                  <p className='mb-3'>Data Updated at {formatDate(product.nav_date, 'DD MMM YYYY')}</p>

                  <br /><br />
                  <div className="row align-items-start">
                    <div className="col text-bold">
                      Net Asset Value/Unit
                    </div>
                    <div className="col">
                      Asset Under Management
                    </div>
                    <div className="col">
                      Total Net Asset Value
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col">{product.fund_ccy} {formatNumber(product.nav_per_unit, 3)}</div>
                    <div className="col">{product.fund_ccy} {formatNumber(product.navAum, 3)}</div>
                    <div className="col">{product.fund_ccy} {formatNumber(product.navUnit, 3)}</div>
                  </div>

                  <br /><br />
                  <div className="row align-items-start">
                    <div className="col text-bold">
                      % daily
                    </div>
                    <div className="col">
                      % monthly
                    </div>
                    <div className="col">
                      % quarterly
                    </div>
                    <div className="col">
                      % yearly
                    </div>
                    <div className="col">
                      % 3 years
                    </div>
                    <div className="col">
                      % YTD
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col align-items-end">
                      {formatNumber(product.navOneday, 2)}
                    </div>
                    <div className="col align-items-end">
                      {formatNumber(product.navOneMonth, 2)}
                    </div>
                    <div className="col">
                      {formatNumber(product.navThreeMonth, 2)}
                    </div>
                    <div className="col">
                      {formatNumber(product.navOneYear, 2)}
                    </div>
                    <div className="col">
                      {formatNumber(product.navThreeYear, 2)}
                    </div>
                    <div className="col">
                      {formatNumber(product.navYtd, 2)}
                    </div>
                  </div>
                </>
              }
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default MutualFund;